import React from "react"
import Img, { FluidObject } from "gatsby-image"

import Wrapper from "../Wrapper"
import Row from "../Row"
import Column from "../Column"
import { BodyLarge, H2 } from "../Typography"

import styles from "./index.module.scss"

type Props = {
  img: FluidObject
  imgAlt: string
  title: string
  description: string
  body: string
}

const AboutPageContent = ({ img, imgAlt, title, description, body }: Props) => (
  <section className={styles.pageContent}>
    <Wrapper>
      <Row>
        <Column mobile={8} desktop={9}>
          <H2 className={styles.title} component="h1">
            {title}
          </H2>
          <BodyLarge>{description}</BodyLarge>
          <div
            dangerouslySetInnerHTML={{ __html: body }}
            className={styles.body}
          />
        </Column>
        <Column mobile={8} desktop={9} className={styles.aboutImage}>
          <Img fluid={img} alt={imgAlt} />
        </Column>
      </Row>
    </Wrapper>
  </section>
)

export default AboutPageContent
