import React from "react"
import { graphql } from "gatsby"
import { FluidObject } from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import AboutPageContent from "../components/AboutPageContent"

type Props = {
  data: {
    area: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
  }
}

const About = ({ data }: Props) => {
  const props = {
    title: "About Us",
    imgAlt: "sun shining over sioux mountain on to a lake",
    description:
      "Ah-shawah-bin Sioux Lookout/Lac Seul Victim Support Services is a community-based service that partners with emergency services and community agency partners, to provide immediate short-term emotional support, practical assistance, and referral services to victims of crime and tragic circumstances. We provide our services 24 hours a day, 7 days a week. ",
    body:
      "<p>We are dedicated to providing a service which is flexible, responsive, and culturally appropriate to all victims.</p> <p>Our specially trained staff respond to provide one on one assistance to victims to help lessen the impact of being re-victimized, and ensure victim is emotionally stable until the victim’s own support network has arrived or has been referred to appropriate community resources. </p><p>We service the Municipality of Sioux Lookout including Hudson and the three communities of Lac Seul First Nation: Frenchman’s Head, Kejick Bay and Whitefish Bay.</p><p>Services each victim receives will depend on their individual needs. We respect the right of every victim to make his/her own decisions. Children under 16 years of age require consent/permission from a parent or guardian.</p><p>Assistance and services is provided free of charge.</p>",
    img: data.area.childImageSharp.fluid,
  }

  return (
    <Layout>
      <SEO title="About Us" />
      <AboutPageContent {...props} />
    </Layout>
  )
}

export default About

export const query = graphql`
  query {
    area: file(relativePath: { eq: "area.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
